<template>
  <div class="game">
    <!-- model error -->
    <v-dialog v-model="error.show" max-width="290">
      <v-card>
        <v-card-title class="text-h5 err">
          กรุณาทำขั้นตอนให้เสร็จก่อนนะคะ
        </v-card-title>
        <v-card-text>
          {{ error.message }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="error.show = false">
            กดเพื่อลองใหม่อีกครั้ง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="logo-web">
      <img src="../assets/ll.png" alt="" width="100%" height="100%" />
    </div>
    <v-card max-height="100%" width="90%" rounded="xl" max-width="600">
      <div class="number text-center pt-5">
        <p>ขั้นตอนที่ {{ e1 }} / 3</p>
      </div>
      <v-stepper flat v-model="e1">
        <!-- กิจกรรม step 1 -->

        <v-stepper-items>
          <v-stepper-content :step="1">
            <div class="content-step text-center">
              <h4>กดเลือกเพื่อนที่คุณสนิท 10 คน</h4>
              <p>เพื่อให้เพื่อนมารับ</p>
              <v-btn color="orange" dark @click="step1" class="mt-4">
                <!-- เปลี่ยสีปุ่ม-->
                กดที่นี่
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>

        <!-- กิจกรรม step 2 -->

        <v-stepper-items>
          <v-stepper-content :step="2">
            <div class="content-step text-center">
              <h4>กดเลือกกลุ่มที่มี คนในกลุ่ม เกิน 300 / 5 กลุ่ม</h4>
              <p>ห้ามส่งให้เพื่อนเด็ดขาด</p>
              <v-btn color="orange" dark @click="step2" class="mt-4">
                <!-- เปลี่ยสีปุ่ม-->
                กดที่นี่
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>

        <!-- กิจกรรม step 3 

        <v-stepper-items>
          <v-stepper-content :step="3">
            <div class="content-step text-center">
              <h4>กดแชร์ให้เพื่อน 4 คน</h4>
              <p>ห้ามเลือกซ้ำกับ ขั้นตอนที่ 1 และ 2</p>
              <v-btn color="orange" dark @click="step3" class="mt-4"> 
                กดแชร์ที่นี่
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items> 
      -->

        <v-stepper-items>
          <v-stepper-content :step="3">
            <div class="content-step text-center">
              <p>สุดท้าย ! เซฟรูป + คัดลอกข้อความ</p>
              <h4>นำไปโพสบนทวิตตัวเอง 🤩</h4>
              <div class="scroll-box" ref="scrollBox" @click="copyText">
                <!-- ข้อความในกล่อง -->
                <p1
                  >🧧 สุ่มแจกทุนฟรี 100 ได้ทุกคน !! 🧧 
                  <br>♻️รีโพส + ❤️กดใจ + อ้างอิงโพส 
                  <br> ทำเสร็จ คลิก » https://bit.ly/WeTruewallet #เครดิตฟรี
                  <br> #สล็อตเครดิตฟรี #ทุนฟรี #สล็อตทุนฟรี #เครดิตฟรีล่าสุด
                  <br> #แจกซองแดง #ทรูวอเลท #แจกจริง #ทุนฟรี100 #แจก100 #แจกฟรี100
                  <br>  #ได้จริง #แจกซองทรู</p1
                >
              </div>
              <img src="../assets/ฟรี200.jpg" alt="" width="50%" height="50%" />
              <br>
              <v-btn color="orange" dark @click="clicktweet" class="mt-4">
                คลิกที่นี่ เพื่อนำรูปไปโพส
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import liff from "@line/liff";
import { flexMessage } from "../service/flex-message";
/* import { lineCaptionImg } from "../service/cation-message"; */
import { lineActionMessage } from "../service/action-message";

export default {
  data: () => ({
    e1: 1,
    error: {
      show: false,
      message: "",
    },
    caption: "",
  }),
  methods: {
    // ปุ่มกดทวีต
    clicktweet() {
      // ตัวอย่างข้อความที่จะทวีต เด้งเข้าแอพ
      const tweetText = `🧧 สุ่มแจกทุนฟรี 100 ได้ทุกคน !! 🧧

♻️รีโพส + ❤️กดใจ + อ้างอิงโพส

ทำเสร็จ คลิก » https://bit.ly/WeTruewallet

#เครดิตฟรี #สล็อตเครดิตฟรี #ทุนฟรี #สล็อตทุนฟรี
#เครดิตฟรีล่าสุด #แจกซองแดง #ทรูวอเลท #แจกจริง
#ทุนฟรี100 #แจก100 #แจกฟรี100 #ได้จริง #แจกซองทรู`;

      // ทวีตข้อความ
      this.tweet(tweetText);
    },
    tweet(text) {
      const twitterAppUrl = `twitter://post?message=${encodeURIComponent(
        text
      )}`;
      const twitterWebUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text
      )}`;

      // ลองเปิดในแอพ Twitter ถ้าไม่ได้ก็เปิดในเว็บ
      window.open(twitterAppUrl, "_blank");

      // หรือถ้าไม่ได้ก็เปิดในเว็บ
      window.open(twitterWebUrl, "_blank");
    },

    // Copy ข้อความ
    copyText() {
      // สร้าง Range
      const range = document.createRange();

      // ดำเนินการเข้าถึง element ที่มี scroll box
      const scrollBox = this.$refs.scrollBox;

      // เลือก element ทั้งหมดภายใน scroll box
      range.selectNodeContents(scrollBox);

      // แสดงตำแหน่งของ Range
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // คัดลอกข้อความ
      document.execCommand("copy");
      alert("คัดลอกเรียบร้อยแล้ว !");
    },

    nextStep(n) {
      if (!n) {
        alert("เด้งไป line กิจกรรม");
      } else {
        this.e1 = n;
      }
    },
    step1() {
      liff.shareTargetPicker([flexMessage, lineActionMessage]).then((res) => {
        if (res === undefined) {
          (this.error.show = true),
            (this.error.message = "โปรดแชร์ให้เพื่อนก่อนนะ");
        } else {
          this.e1 = 2;
        }
      });
    },
    step2() {
      liff.shareTargetPicker([flexMessage, lineActionMessage]).then((res) => {
        if (res === undefined) {
          (this.error.show = true),
            (this.error.message = "โปรดแชร์ให้เพื่อนก่อน");
        } else {
          this.e1 = 3;
        }
      });
    },
    /* step3() {
        liff.shareTargetPicker([lineCaptionImg()]).then((res) => {
          if (res === undefined) {
            (this.error.show = true),
              (this.error.message = "โปรดแชร์ให้เพื่อนก่อน");
          } else {
            this.e1 = 4;
          }
        });
    }, */
    step4() {
      liff
        .sendMessages([
          {
            type: "text",
            text: "ขอรูปและแคปชั่น",
          },
        ])
        .then(() => {
          liff.openWindow({
            url: "https://lin.ee/gwehum9",
            external: true,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-box {
  max-height: 70px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 5px;

  p1 {
    font-size: 10px; /* ปรับขนาดข้อความตามที่ต้องการ */
    color: #333; /* ปรับสีข้อความ */
  }
}
.logo-web {
  width: 100px;
  height: 100px;
  margin-bottom: 50px;
  transform: scale(2); /* เพิ่มขนาดโลโก้ ... เท่า */
  img {
    object-fit: cover;
  }
}
.game {
  height: 100vh;
  width: 100vw;

  // สีพื้นหลัง (ไล่สี)
  /* background: radial-gradient(at 100% 50%, #5f5f5f, rgba(0, 0, 0, 0) 80vw), radial-gradient(at 0% 50%, #be643a, rgba(0, 0, 0, 0) 80vw) ; */
  /*  background-image: url(../assets/bg.jpg); */
  background-color: #ffffff;
  background-size: cover, contain;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.number {
  p {
    color: orange; // สีขั้นตอนที่....
  }
}

.content-step {
  // background-color: grey;
  width: 100%;
  p {
    color: rgb(255, 0, 0);
  }
}
</style>
