export const flexMessage = {
  type: "flex",
  altText: "เพื่อนรีบเข้ามารับซองฟรี 100.- จากทรูวอเลท",
  contents: {
    "type": "bubble",
    "hero": {
      "type": "image",
      "url": "https://img2.pic.in.th/pic/Truemoney-Wallet.jpeg",
      "size": "full",
      "aspectRatio": "16:5",
      "offsetTop": "0px",
      "offsetBottom": "0px",
      "offsetStart": "0px",
      "offsetEnd": "0px"
    },
    "body": {
      "type": "box",
      "layout": "vertical",
      "contents": [
        {
          "type": "text",
          "text": "แจกฟรี 100.- จำนวนจำกัด",
          "weight": "bold",
          "gravity": "center",
          "align": "center"
        },
        {
          "type": "image",
          "url": "https://img5.pic.in.th/file/secure-sv1/200-2.jpeg",
          "size": "full",
          "align": "center",
          "gravity": "center",
          "aspectRatio": "9:16"
        },
        {
          "type": "button",
          "action": {
            "type": "uri",
            "label": "กดรับซองวอเลท 100 ฟรี",
            "uri": "https://lin.ee/gwehum9"
          },
          "color": "#E67E22",
          "style": "primary"
        }
      ],
      "offsetTop": "0px",
      "offsetBottom": "0px",
      "offsetStart": "0px",
      "offsetEnd": "0px",
      "paddingAll": "0px"
    }
  }
}